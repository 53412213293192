import { memo } from '../../../../util/memo';
import { MatchDetailsScoreline } from './MatchDetailsScoreline';
import Stack from '@mui/material/Stack';
import { useMatchSession } from '../../../../hooks/tournaments/useMatchSession';
import { AddScreenshot } from '../../../data-entry-ui/specific/AddScreenshot';
import { OpenMatchLobbyButton } from './OpenMatchLobbyButton';
import { SessionScreenshotCarousel } from './SessionScreenshotCarousel';
import { useMatchPermissions } from '../../../../hooks/tournaments/useMatchPermissions';
import { useActiveMatchRouter } from '../../../../contexts/ActiveMatchRouterContext';

export const SessionDetails = memo(function SessionDetailsUnmemoized() {
  const { sessionNumber } = useActiveMatchRouter();
  const { competitor1Outcomed, competitor2Outcomed } =
    useMatchSession(sessionNumber);
  const { canEditScreenshot } = useMatchPermissions();

  const showOpenMatchLobbyButton =
    competitor1Outcomed?.name && competitor2Outcomed?.name;

  return (
    <Stack p={4} spacing={4}>
      <MatchDetailsScoreline
        competitor1={competitor1Outcomed}
        competitor2={competitor2Outcomed}
      />
      {canEditScreenshot && (
        <Stack direction="row" spacing={4}>
          {showOpenMatchLobbyButton && <OpenMatchLobbyButton />}
          <AddScreenshot />
        </Stack>
      )}
      <Stack width="100%" justifyContent="center" alignItems="center" mb={4}>
        <SessionScreenshotCarousel />
      </Stack>
    </Stack>
  );
});
